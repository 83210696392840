import React from 'react'
import PropTypes from 'prop-types'
import { map, get, isEqual } from 'lodash'

import FormItem from '../../atoms/FormItem'
import FormError from '../../atoms/FormError'
import SelectField from '../../atoms/SelectField'

const FormItems = ({ items, errors, values, handleChange, touched, handleBlur, setFieldValue }) =>
  map(items, ({ name, type, placeholder, options }) => {
    if (isEqual(type, 'select')) {
      return (
        <div key={name}>
          <SelectField
            name={name}
            value={get(values, name)}
            error={get(touched, name) && get(errors, name)}
            onBlur={handleBlur}
            options={options}
            onChange={setFieldValue}
            placeholder={placeholder}
            handleChange={handleChange} />
          <FormError
            error={get(touched, name) && get(errors, name)}
            data-testid={`error-input-${name}`} />
        </div>
      )
    }

    return (
      <div key={name}>
        <FormItem
          name={name}
          type={type}
          error={get(touched, name) && get(errors, name)}
          value={get(values, name, '')}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={placeholder}
          data-testid={`input-${name}`} />
        <FormError
          error={get(touched, name) && get(errors, name)}
          data-testid={`error-input-${name}`} />
      </div>
    )
  })

FormItems.proTypes = {
  items: PropTypes.array.isRequired,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object.isRequired,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

FormItems.defaultProps = {
  errors: {},
  touched: {},
  handleBlur: () => {}
}

export default FormItems
