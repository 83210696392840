import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  gap: 16px;
`

const Title = styled.span`
  font-family: 'Source Sans Pro'; 
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: ${({ theme, color }) => get(theme, color, '#FFF')};
`

const Subtitle = styled.span`
  font-family: 'Source Sans Pro'; 
  font-size: 18px;
  font-weight: 600;
  line-height: 1.61;
  color: ${({ theme, color }) => get(theme, color, '#FFF')};
`

const InvestorSidebarTitle = ({ color }) => (
  <Container>
    <Title color={color}>
      Réduisez vos impôts tout en développant votre patrimoine.
    </Title>
    <Subtitle color={color}>
      Réussissez votre projet avec nos experts en investissement immobilier (Pinel, LMNP, déficit foncier)
    </Subtitle>
  </Container>
)

InvestorSidebarTitle.propTypes = {
  color: PropTypes.string
}

InvestorSidebarTitle.defaultProps = {
  color: 'white'
}

export default InvestorSidebarTitle
