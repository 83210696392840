import React from 'react'
import styled from 'styled-components'

import InvestorSidebarForm from '../../molecules/InvestorSidebarForm'
import InvestorSidebarTitle from '../../molecules/InvestorSidebarTitle'
import InvestorAcquisitionBackground from '../../molecules/InvestorAcquisitionBackground'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/b8wtseo'

const Container = styled.div`
  display: flex;
`

const FormSection = styled.div`
  padding: 32px 40px;
  height: 100vh;
  overflow: auto;
  max-width: 380px;
`

const InvestorAcquisitionDesktop = ({ id }) => (
  <Container>
    <InvestorAcquisitionBackground id={id} />
    <FormSection>
      <InvestorSidebarTitle color='blue' />
      <InvestorSidebarForm submit={SUBMIT} isWhite={false} />
    </FormSection>
  </Container>
)

export default InvestorAcquisitionDesktop
