import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'
import { get, nth, first } from 'lodash'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'

const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  justify-content: space-between;
  min-height: 440px;

  ${media.greaterThan('lg')`
    height: 100vh;
    justify-content: flex-start;
    position: fixed; 
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}
`

const LogoContainer = styled.div`
  max-width: 250px;
  margin-left: 0.5rem;  
  ${media.lessThan('lg')`
    display: none;
  `}
`

const StyledLogo = styled(Logo)`
  width: auto;
  height: 100%;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.greaterThan('lg')`
    padding-top: 55px;
    padding-left: 95px;
  `}
`

const DataItem = styled.div`
  max-width: 268px;
  border-radius: 15px;
  padding: 11px 16px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
`

const DataText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('lg')`
    font-size: 16px;
    line-height: 1.19;
  `}

  & > b {
    color: ${({ theme }) => get(theme, 'brightOrange')};
  }

  & > span {
    font-size: 16px;
    font-weight: normal;

    ${media.lessThan('lg')`
      font-size: 14px;
    `}
  }
`

const InvestorAcquisitionBackground = ({ id }) => {
  const { placeholderImages, mountainImages } = useStaticQuery(graphql`{
    placeholderImages: allFile(
      filter: { relativeDirectory: { eq: "investorv2" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const image = getImage(nth(get(placeholderImages, 'nodes'), id - 1) || first(get(placeholderImages, 'nodes')))
  const bgImage = convertToBgImage(image)

  return (
    <StyledImageSection
      alt='Programmes immobiliers neufs'
      Tag='div'
      {...bgImage}
      preserveStackingContext>
      <LogoContainer>
        <StyledLogo
          blue={true}
          classic={true} />
      </LogoContainer>
      <DataContainer>
        <DataItem>
          <DataText>
            <b>Jusqu’à 63 000€</b><br />
            de réduction d’impôts
          </DataText>
        </DataItem>
        <DataItem>
          <DataText>
            Investissements immobiliers<br />
            <span>dans toute la France</span>
          </DataText>
        </DataItem>
      </DataContainer>
    </StyledImageSection>
  )
}

export default InvestorAcquisitionBackground

