import React from 'react'
import styled from 'styled-components'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import InvestorSidebarForm from '../../molecules/InvestorSidebarForm'
import InvestorSidebarTitle from '../../molecules/InvestorSidebarTitle'
import InvestorAcquisitionBackground from '../../molecules/InvestorAcquisitionBackground'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/b8wtseo'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  padding: 24px 72px;

  ${media.lessThan('md')`
    padding: 24px 40px;
  `}

  ${media.lessThan('sm')`
    padding: 24px;
  `}
`

const LogoContainer = styled.div`
  margin-bottom: 32px;
  max-width: 200px;
`

const FormSection = styled.div`
  padding: 0 32px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InvestorAcquisitionMobile = ({ id, hiddenRef }) => (
  <Container>
    <TopSection>
      <LogoContainer>
        <Logo classic={true} />
      </LogoContainer>
      <InvestorSidebarTitle color='blue' />
    </TopSection>
    <InvestorAcquisitionBackground id={id} />
    <FormSection>
      <InvestorSidebarForm
        submit={SUBMIT}
        isWhite={false}
        hiddenRef={hiddenRef} />
    </FormSection>
  </Container>
)

export default InvestorAcquisitionMobile

