import styled from 'styled-components'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { isEqual, get, isNull } from 'lodash'
import React, { useRef, useState, useCallback, useEffect } from 'react'

import theme from '../../../config/theme'
import media from '../../../utils/media'
import useIsMounted from '../../../hooks/useIsMounted'

const ESCAPE_KEY = 27

const Container = styled.div.attrs(({ backgroundBlur }) => ({
  backgroundBlur
}))`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  background-color: rgba(0, 0, 0, .5);
  backdrop-filter: ${({ backgroundBlur }) => isEqual(backgroundBlur, true) ? 'blur(10px)' : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const Content = styled.div`
  position: relative;
  min-width: 20vw;
  width: auto;
  min-height: 20vh;
  background-color: ${get(theme, 'white')};
  display: flex;
  flex-direction: column;
  border-radius: 25px;

  ${media.lessThan('sm')`
    width: 90%;
    min-width: 90%;
  `}
`

const CustomModal = ({ children, isOpen, onRequestClose, backgroundBlur = false, ...props }) => {
  const node = useRef(null)
  const isMounted = useIsMounted()
  const [isClicked, updateIsClicked] = useState(false)

  const handleClick = useCallback((evt) => {
    evt.stopPropagation()
    const { target } = evt
    if (!isClicked) {
      return updateIsClicked(true)
    }

    if (isEqual(node.current?.contains(target), true)) {
      return
    }

    if (isEqual(isOpen, true)) {
      updateIsClicked(false)
      return onRequestClose()
    }
  }, [isOpen, onRequestClose, node, isClicked, updateIsClicked])

  const handleKeyDown = useCallback(({ keyCode }) => {
    if (!isEqual(keyCode, ESCAPE_KEY)) {
      return
    }

    if (isEqual(isOpen, true)) {
      return onRequestClose()
    }
  }, [isOpen, onRequestClose, node])

  useEffect(() => {
    if (isEqual(isMounted, true) && isEqual(isOpen, true)) {
      document.addEventListener('click', handleClick)
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('click', handleClick)
        document.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [isMounted, isOpen])

  if ((!isEqual(isOpen, true)) || isEqual(isMounted, false) || isNull(children) || typeof document === 'undefined') {
    return null
  }

  return createPortal(
    <Container backgroundBlur={backgroundBlur} onClick={handleClick}>
      <Content ref={node} {...props}>
        {children}
      </Content>
    </Container>,
    document.getElementById('portal')
  )
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  backgroundBlur: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired
}

CustomModal.defaultProps = {
  backgroundBlur: false
}

export default CustomModal
