import React, { useRef } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../../atoms/Seo'
import media from '../../../utils/media'
import JsonLD from '../../atoms/JsonLD'
import InvestorAcquisitionMobile from '../../organisms/InvestorAcquisitionMobile'
import InvestorAcquisitionDesktop from '../../organisms/InvestorAcquisitionDesktop'
import ProgramsAcquisitionMobileButton from '../../organisms/ProgramsAcquisitionMobileButton'

const TITLE = 'Formulaire investisseur immobilier'
const DESCRIPTION = 'Réduisez vos impôts tout en développant votre patrimoine avec Folhomee'

const DesktopContainer = styled.div`
  display: block;

  ${media.lessThan('lg')`
    display:none;
  `}
`

const MobileContainer = styled.div`
  display: block;

  ${media.greaterThan('lg')`
    display:none;
  `}
`

const InvestisseurImmobilier = ({ pageContext: { id }, location }) => {
  const hiddenRef = useRef()

  const keywords = ['Folhomee', 'Programme', 'Neuf', 'Immobilier', 'Appartement']

  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description: DESCRIPTION,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: keywords.join(', ')
  }

  return (
    <>
      <SEO
        title={TITLE}
        location={location}
        description={DESCRIPTION}>
          <JsonLD>
            {linkedData}
          </JsonLD>
        </SEO>
      <DesktopContainer>
        <InvestorAcquisitionDesktop id={id} />
      </DesktopContainer>
      <MobileContainer>
        <ProgramsAcquisitionMobileButton
          label='Simulation Gratuite'
          hiddenRef={hiddenRef} />
        <InvestorAcquisitionMobile
          id={id}
          hiddenRef={hiddenRef} />
      </MobileContainer>
    </>
  )
}

InvestisseurImmobilier.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default InvestisseurImmobilier
