import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import useInterval from '../../../hooks/useInterval'

const ToastContainer = styled.div`
  width: 98%;
  font-family: 'Source Sans Pro';
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  font-size: .875rem;
  background-color: rgba(255,255,255,.85);
  background-clip: padding-box;
  margin-top: 10px;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  opacity: ${props => get(props, 'show', false) ? 1 : 0};
  display: ${props => get(props, 'show', false) ? 'block' : 'none'};
  border-radius: .25rem;
  transition: opacity .15s linear;
`

const ToastBody = styled.div`
  padding: .75rem;
`

const Toast = ({ onClose, children, delay, show, ...props }) => {
  useInterval(() => onClose(), [delay])

  return (
    <ToastContainer show={show} {...props}>
      <ToastBody>
        {children}
      </ToastBody>
    </ToastContainer>
  )
}

Toast.propTypes = {
  show: PropTypes.bool.required,
  delay: PropTypes.number.required,
  onClose: PropTypes.func.required,
  children: PropTypes.any.required
}

const ToastComponent = ({ show, children, onClose, delay, ...props }) => {
  if (!show) {
    return null
  }

  return (
    <Toast
      {...props}
      show={show}
      delay={delay}
      onClose={onClose}>
      {children}
    </Toast>
  )
}

ToastComponent.propTypes = {
  show: PropTypes.bool,
  delay: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.any
}

ToastComponent.defaultProps = {
  show: false,
  delay: 6000,
  onClose: () => {},
  children: () => null
}

export default ToastComponent
